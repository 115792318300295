.terms-section {
  font-family: 'Noto Sans TC', sans-serif;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  /* Aligns items horizontally in the center */
  justify-content: center;
  /* Aligns items vertically in the center */
  text-align: center;
  padding: 20px;
  margin: auto;
  padding-top: 0px;
  /* Adjust the height as necessary */
}

span {
  color: #666666;
}

.terms-section h2 {
  font-size: 1.2em;
  /* Adjust size as needed */
  margin-bottom: 10px;
  /* Spacing below the title */
  color: #0081CA;
  text-align: left;
}

.terms-section  {
  margin-bottom: 0px;
  color : #666666;
  font-size: 0.8em;
  font-weight: bold;
  text-align: left;
  align-items: left;
}



