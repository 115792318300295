/* Pagination.css */
.pagination {
  display: flex;
  justify-content: center;
  /* Align buttons to the right */
  padding: 20px;
  /* Add padding as needed */
  list-style: none;
  /* Remove default list styling if using <ul> */
}

.page-item {
  padding: 5px 10px;
  /* Adjust padding as needed */
  margin-left: 5px;
  /* Space between buttons */
  border: 1px solid #ddd;
  /* Border for buttons */
  cursor: pointer;
}

.page-item.active {
  background-color: #000000;
  /* Highlight color for active page */
  color: white;
  /* Text color for active page */
  border-color: #000000;
}