.cart-container {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Noto Sans TC', sans-serif;
}

.item-container {
  display: flex;
  margin-bottom: 20px;
}

.cart-left {
  width: 30%;
}

.item-image {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.cart-right {
  width: 70%;
  padding-left: 20px;
}

.cart-title {
  display: flex;
  justify-content: space-between;
  color: #666666;
  font-weight: bold;
}

.cart-break-line {
  border: 0;
  height: 1px;
  background-color: #ccc;
  margin: 1px ;
}

.details {
  display: flex;
  align-items: flex-start;
  color: #666666;
  font-size: 0.9em;
}

.inside-left {
  width: 70%;
}

.inside-right {
  width: 30%;
  text-align: right;
}

.price {
  color: #666;
}

.subtotal {
  font-size: 1.2em;
  color: #0081CA;
  align-items: center;
}

.subtotal img{
  width: 15px;
  padding-right: 3px;
}

.break-line-long {
  width: 100%;
  border-top: 1px solid #ccc;
  margin-top: 30px; /* Adjust margin as needed */
  margin-bottom: 30px; /* Adjust margin as needed */
}

.cart-head-title {
  text-align: center;
    margin-bottom: 40px;
    color: #0081CA;
    font-size: 1em;
}

.delete_button {
  text-align: right;
  color: #666666;
  font-size: 0.9em;
  cursor: pointer;
  font-weight: bold;
}

.check-counter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.counter-left {
  margin-left: 2%;
  flex: 1;
}

.counter-right {
  margin-right: 2%;
  display: flex;
}

.counter-title {
  text-align: left;
  color: #666666;
  font-size: 1.2em;
}

.icon {
  width: 30px;
  padding-right: 3px;
  margin: 0;
}

.counter-content {
  text-align: right;
  align-items: center;
  font-size: 1.7em;
  color: #6513DB;
  font-weight: bold;
}

.counter-content img {
  text-align: right;
  align-items: center;
  width: 20px;
  padding-right: 4px;
}

.button-div {
  margin: 10px 0px 50px 0px;
  text-align: center;
}

.cart-button {
  width: 20%;
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  color: white; /* Text color */
  background-image: linear-gradient(135deg, #FFFFFF 1%,violet 5% ,#6513DB 84%); /* Gradient from purple to violet */
  border: none; /* No border */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer; /* Cursor indicates clickable */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.cart-button-grey {
  width: 20%;
  padding: 10px 20px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  color: white; /* Text color */
  background-image: linear-gradient(135deg, #FFFFFF 1%,rgb(231, 231, 231) 5% ,#110f13 84%); /* Gradient from purple to violet */
  border: none; /* No border */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer; /* Cursor indicates clickable */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.cart-button:hover {
  background-image: linear-gradient(225deg, #FFFFFF 1%,violet 5% ,#6513DB 84%); /* Slightly change gradient on hover */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Optional: Add shadow on hover */
}

.cart-buttom {
  margin-bottom: -20%;
  padding: 10%;
  z-index: 99999;
}

.no-product {
  text-align: center;
  color: #666666;
}