.confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white background */
  backdrop-filter: blur(5px); /* Apply blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 13;
}

.confirmation-dialog {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add shadow effect */
}

.confirmation-dialog h2 {
  margin-top: 0;
}

.confirmation-dialog p {
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.button-container button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-container button:first-child {
  background-color: #ccc;
}

.button-container button:last-child {
  background-color: #007bff;
  color: white;
}

.Confirm-total-Price {
  color: #007bff;
}

.confirmation-dialog button {
  width: 100px;
  margin: 10px;
  background-color: #6513DB;
  /* Set the background color to purple */
  color: white;
  /* Set the text color to white for better contrast */
  border: none;
  /* Remove default border */
  padding: 2px 15px;
  /* Add some padding */
  border-radius: 1px;
  /* Optional: add border-radius for rounded corners */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  font-size: 1em;
  /* Adjust font size as needed */
}

.confirmation-dialog button:disabled{
  width: 100px;
  margin: 10px;
  background-color: #666666;
  /* Set the background color to purple */
  color: white;
  /* Set the text color to white for better contrast */
  border: none;
  /* Remove default border */
  padding: 2px 15px;
  /* Add some padding */
  border-radius: 1px;
  /* Optional: add border-radius for rounded corners */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  font-size: 1em;
  /* Adjust font size as needed */
}
