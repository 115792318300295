.contact-div h3 {
  font-size: 1.5em;
  /* Adjust size as needed */
  margin-bottom: 10px;
  /* Spacing below the title */
  color: #000000;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.right-title {
  margin-bottom: 20px;
}

.contact-div h2 {
  font-size: 1.2em;
  /* Adjust size as needed */
  margin-bottom: 10px;
  /* Spacing below the title */
  color: black;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.contact_content {
  margin-bottom: 20px;
}
.split-component {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  text-align: center;
  padding: 20px;
  margin: auto;
  padding-top: 0px;
}

.left-column, .right-column {
  width: 50%;
  height : 400px;
  padding: 20px; /* Add some padding for spacing */
}

.left-column img {
  width: 80%; /* The image will take up the full width of the left column */
  height: auto; /* This will keep the image aspect ratio intact */
}

.right-column {
  text-align: left; /* Aligns the text to the left */
}

.right-column h2 {
  margin-top: 0; /* Adjusts spacing to the top of the container */
  /* Add more styles for the title as needed */
}

.right-column p {
  margin: 0px;
  margin-bottom: 2px;
  color : #666666;
  font-size: 0.8em;
  font-weight: bold;
}

@media (max-width: 768px) {
  .split-component {
    flex-direction: column;
  }

  .left-column img {
    object-position: center; /* Center the image when stacked on smaller screens */
  }

  .left-column, .right-column {
    width: 100%; /* Each column takes full width on smaller screens */
    padding: 10px; /* Adjust padding as needed */
  }
}
