/* Products.css */


.products-container {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.products-container h2 {
  font-size: 1em;
  /* Adjust size as needed */
  margin-bottom: 30px;
  margin-top: 30px;
  /* Spacing below the title */
  color: #000000;
}

.product-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  align-items: center;
}

.product-card {
  border: 0px solid #ccc;
  margin-bottom: 10px;
  width: 30%;
  min-width: 30%;
  height: 320px;
  /* Adjust for 3 products per row with spacing */
  box-sizing: border - box;
  padding: 0px 10px;
}

.product-image {
  width: 100%;
  /* Set a fixed width */
  height: 75%;
  /* Set a fixed height to maintain a 1:1 aspect ratio */
  object-fit: cover;
  /* Ensures the image covers the area without stretching */
  border-radius: 15px;
  border: 0px solid #00CDAC;
  /* As per your requirement */
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
}

.product-info {
  height: 30px;
  display: flex;
  justify-content: space - between;
  padding: 10px 0px 0px 0px;
  margin: 0px;
}

.product-name {
  width: 70%;
  padding-left: 10px;
  align-items: center;
  text-align: left;
  font-size: 0.9em;
  color: #4D4D4D;
}

.product-add {
  width: 30%;
  align-items: center;
  text-align: right;
  padding-right: 10px;
}

.product-add img {
  width: 13px;
}

.product-price-front {
  width: 100%;
  justify-content: flex-end;
  /* Align items to the right */
  align-items: center;
  /* Align items vertically */
  text-align: right;
  color: #000000;
  font-size: 1.2em;
  padding-right: 5px;
}

.product-icon {
  padding-right: 3px;
  width: 13px;
}

.product-details {
  display: flex;
  justify-content: space - between;
  padding: 0px 0px;
}

.product-description {
  width: 80%;
  color: #666666;
  font-size: 0.8em;
}

.product-button {

  background-color: #666666;
  color: white;
  border: none;
  padding: 5px 0px;
  width: 20%;
  height: 30px;
  cursor: pointer;
  align-items: right;
}

/* Add responsive styling and pagination styles as needed */