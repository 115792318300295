/* Header.css */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&display=swap');

header {
  background-color: #fff;
  /* Set background to white */
  color: #333;
  /* Set text color to a darker shade for contrast */
  max-width: 800px;
  /* Set the maximum width of the header */
  width: 100%;
  /* Ensure it takes up the full width up to the max */
  margin: 0 auto;
  /* Center the header if the view is wider than max-width */
  padding: 25px 0px 0px 0px;
  /* Adjust padding to your design */

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left,
.header-right {
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  align-items: center;
}

.search-button {
  width: 20px;
  height: auto;
  padding-right: 20px;
  cursor: pointer;
}

.home-button {
  width: 20px;
  height: auto;
  padding-right: 20px;
  cursor: pointer;
}

.language-switch span {
  cursor: pointer;
  margin-right: 5px;
  color: #000;
  padding: 0px;
  /* Default color */
  font-size: 1em;
  /* Default font weight */
}

.language-switch .active-language {
  color: #0081CA;
  font-weight: bold;
}

.login-button {
  background-color: #000000;
  /* Set the background color to purple */
  color: white;
  /* Set the text color to white for better contrast */
  border: none;
  /* Remove default border */
  padding: 2px 15px;
  /* Add some padding */
  border-radius: 1px;
  /* Optional: add border-radius for rounded corners */
  cursor: pointer;
  /* Change cursor to pointer on hover */
  font-size: 1em;
  /* Adjust font size as needed */
}

.login-button:hover {
  background-color: darkpurple;
  /* Slightly darker purple on hover for effect */
}

.header-user-info {
  display: flex;
  align-items: normal;
  /* Align items vertically center */
  justify-content: center;
  /* Align items horizontally center */
  align-items: center;
  align-self: center;
}

.header-user-info img {
  align-items: center;
  width: 20px;
  padding-left: 0px;
  padding-right: 5px;
}

.header-user-info span {
  display: flex;
  margin-right: 0px;
  color: #0081CA;
  /* Default color */
  font-weight: bold;
  font-size: 1em;
  /* Default font weight */
}

.header-user-welcome span {
  color: #666666;
  font-weight: 400;
  padding-right: 30px;
}

.cart-icon-container {
  position: relative;
}

.red-circle {
  position: absolute;
  bottom: 0px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-number {
  color: white;
  font-size: 14px;
  margin-bottom: 2px;
  margin-left: 0px;


}