/* LoginComponent.css */
.login-container {
  display: flex;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  margin-bottom: 40px;
}

.login-left {
  flex: 1;
  padding: 20px;
}

.login-right {
  flex: 1;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  padding: 20px;
}

.login-title {
  color: grey;
}

.login-description {
  font-size: 0.8em;
  color: #606060;
}

.login-form-group {
  margin-bottom: 20px;
}

.login-form-group label {
  display: block;
  color: grey;
  margin-bottom: 5px;
}

.login-form-group input {
  width: 90%;
  padding: 10px;
  border: 1px solid #000000;
  margin-bottom: 10px;
}

.login-form-group input::placeholder {
  color: grey;
  opacity: 1;
  font-style: normal;
}

/* Style for password input field */
.login-form-group input[type="password"] {
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #000000;
}

/* Example of a focused state style */
.login-form-group input[type="password"]:focus {
  border-color: #000000;
  /* Border color when the input field is focused */
  outline: none;
  /* Remove default focus outline */
}

/* Example of a style for an invalid input */
.login-form-group input[type="password"]:invalid {
  border-color: rgb(128, 128, 128);
  /* Border color for invalid input */
}



.login-bottom {
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.remember-me {
  display: flex;
  align-items: center;
}

.remember-me input[type="checkbox"] {
  margin-right: 5px;
}

.remember-me label {
  color: grey;
}

a {
  color: #000000;
  text-decoration: none;
}

.login-button {
  background-color: #000000;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}

.signup-title {
  margin-left: 20px;
  color: white;
  margin-bottom: 20px;
}

.signup-button {
  margin-left: 20px;
  width: 30%;
  background-color: white;
  color: #000000;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
}

.toggle-password-button {
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  /* Style the button as needed */
}


@media (max-width: 768px) {
  .login-container {
    flex-direction: column;
  }

  .login-right {
    order: -1;
  }
}