.container {
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin: auto;
}

.image-slider-container {
  position: absolute;
  width: 250px;
  /* Set the width of the slider container */
  margin: 0 auto;
  /* Center the slider horizontally */
  /* Force 1:1 aspect ratio */
  position: relative;
  padding-top: 1%;
  /* Set padding-top to create 1:1 aspect ratio */

}

.slick-slider {
  position: absolute;
  width: 250px;
  height: 250px;
  /* Make the slider fill the container */
  border-radius: 15px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}

.image-slider-container img {
  aspect-ratio: 1/1;
}

.dot-buttons {
  margin-top: 15px;
  display: flex;
  /* Display the buttons in a row */
  justify-content: center;
  gap: 6px;
  /* Adjust the space between buttons */
}

.dot-buttons button {
  width: 5px;
  /* Set the width of each dot */
  height: 10px;
  /* Set the height of each dot */
  border-radius: 50%;
  /* Make each dot a circle */
  background-color: #666666;
  /* Set the background color to black */
  border: none;
  /* Remove border */
  cursor: pointer;
  /* Add cursor pointer */
}

.dot-buttons button:hover {
  background-color: #000000;
  /* Darken the color on hover */
}

.dot-buttons button:focus {
  outline: none;
  /* Remove focus outline */
}


.left {
  width: 30%;
  /* Adjust width as needed */
  padding: 0;
  margin: 0;
}

.left hr {
  width: 15%;
  /* Adjust width as needed */
}

.price-div {
  display: flex;
  /* Display the buttons in a row */
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.image-buttons {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.image-buttons img {
  width: 18px;
  padding-right: 3px;

}

.product-price img {
  width: 15px;
  margin-right: 3px;
}

.product-price {
  font-size: 1.2em;
  margin: auto;
  margin-right: 20px;
}

.price-name {
  font-size: 0.9em;
  color: #666666;
  text-align: left;
  margin: auto;
  width: 60%;
  margin-left: 30px;
}

.image-info img {
  width: 100px;
  /* Adjust image width as needed */
  height: auto;
}

.text-content {
  width: 85%;
  margin: auto;
  text-align: center;
  color: #666666;
  font-size: 0.8em;
}

.right {
  width: 45%;
  /* Adjust width as needed */
  flex-direction: column;
}

.submit-area {
  width: 20%;
  /* Adjust width as needed */
  text-align: right;
}

.submit-title {
  position: relative;
  color: #666666;
  font-size: 0.9em;
  font-weight: bold;
}

.submit-points {
  margin-bottom: 20px;
}

.submit-points img {
  width: 20px;
  margin-right: 5px;
}

.submit-price {
  font-size: 1.7em;
  color: #000000;
  font-weight: bold;
}

.right-title {
  position: relative;
  margin-top: -10px;
  color: #666666;
}

.right-brand {
  padding-top: 20px;
}

.right-details {
  padding-top: 20px;
}


.right-brand p {
  position: relative;
  margin: 0px;
  color: #666666;
  font-size: 0.9em;
}

.right-details p {
  margin: 0px;
  color: #666666;
  font-size: 0.9em;
}

.image-text {
  /* Style your image with text container */
}

.image-text img {
  width: 100px;
  /* Adjust image width as needed */
  height: auto;
}

.submit-button {
  width: 15%;
  /* Adjust width as needed */
  padding: 0;
  margin: 0;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20%;
}

.counter button {
  padding: 8px 0px;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  background-color: white;
  color: #0081CA;
  cursor: pointer;
}

.counter .value {
  font-size: 1em;
  padding-left: 10px;
  text-align: center;
  align-items: center;
}



.gradient-button {
  padding: 10px 20px;
  /* Adjust padding as needed */
  font-size: 16px;
  /* Adjust font size as needed */
  color: white;
  /* Text color */
  background-image: linear-gradient(135deg, #FFFFFF 1%, rgb(90, 90, 90) 5%, #000000 84%);
  /* Gradient from purple to violet */
  border: none;
  /* No border */
  border-radius: 10px;
  /* Rounded corners */
  cursor: pointer;
  /* Cursor indicates clickable */
  transition: all 0.3s ease;
  /* Smooth transition for hover effects */
}

.gradient-button:hover {
  background-image: linear-gradient(225deg, #FFFFFF 1%, rgb(90, 90, 90) 5%, #000000 84%);
  /* Slightly change gradient on hover */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  /* Optional: Add shadow on hover */
}

.rem-details {
  font-size: 0.8em;
  color: #666666;
}

.point-form {
  color: #666666;
  font-size: 0.85em;
}