
.top-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.top-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #666666;
  cursor: pointer;
}

.top-button.selected {
  font-weight: bold;
  color: #0081CA;
}

.separator {
  margin: 0 10px;
  color: #666666;
}


.record-break-line {
  border: 0;
  height: 1px;
  background-color: #ccc;
  margin-top: 15px ;
  margin-bottom: 15px;
}

.records-container {
  display: flex;
  flex-direction: column;
}

.record {
  margin-bottom: 0px;
  cursor: pointer;
}

.points-record-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.points-record-div p{
color: #666666;
}

.record p{
margin: 0;
color: #666666;
}

.points-record-title {
  text-align: left;
  align-items: center;
}

.points-record-value {
  text-align: right;
  align-items: center;
  color: #0081CA;
  font-size: 1.2em;
  font-weight: bold;
}

.points-record-value img{
  width: 15px;
  padding-right: 3px;
  align-items: center;

}
