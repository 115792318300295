@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700&display=swap');


.about-section {
  font-family: 'Noto Sans TC', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  /* Aligns items horizontally in the center */
  justify-content: center;
  /* Aligns items vertically in the center */
  text-align: center;
  padding: 20px;
  margin: auto;
  padding-top: 0px;
  /* Adjust the height as necessary */
}

.about-section h2 {
  font-size: 1.5em;
  /* Adjust size as needed */
  margin-bottom: 10px;
  /* Spacing below the title */
  color: #000000;
}

.about-section p {
  margin-bottom: 0px;
  color: #666666;
  font-size: 0.9em;
  font-weight: bold;
}

.aboutus-image-container {
  display: flex;
  /* Use flexbox to arrange images in a row */
  padding: 2% 20% 2% 20%;
}

.aboutus-image {
  width: 60px;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain aspect ratio */
  margin-right: 10px;
  /* Add space between images */
}



.icon-container {
  margin-right: 3%;
  margin-top: 70px;
  padding: auto;
  display: flex;
  justify-content: center;
  gap: 80px;
  /* Space between icons */
}

.icon {
  text-align: center;
}

.gradient-button {
  padding: 10px 20px;
  /* Adjust padding as needed */
  font-size: 16px;
  /* Adjust font size as needed */
  color: white;
  /* Text color */
  background-image: linear-gradient(135deg, #FFFFFF 1%, violet 5%, #6513DB 84%);
  /* Gradient from purple to violet */
  border: none;
  /* No border */
  border-radius: 10px;
  /* Rounded corners */
  cursor: pointer;
  /* Cursor indicates clickable */
  transition: all 0.3s ease;
  /* Smooth transition for hover effects */
}

.gradient-button:hover {
  background-image: linear-gradient(225deg, #FFFFFF 1%, violet 5%, #6513DB 84%);
  /* Slightly change gradient on hover */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  /* Optional: Add shadow on hover */
}


.line-image {
  margin: 10px 0px 20px 0px;
}