/* Footer.css */
.footer {
  background-color: #000000;
  color: white;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  /* Prevent wrapping */
}

.footer-left {
  flex: 1;
  /* Each side takes up equal space */
  text-align: left;
  padding: 10px;
}

.footer-right {
  flex: 0.35;
  /* Each side takes up equal space */
  text-align: center;
  align-items: center;
  padding: 10px;
  font-size: 0.8em;
}

.footer-right {
  text-align: left;
  /* Align text to the left for consistency */
}

.footer-logo {
  max-width: 200px;
  /* Adjust the size as needed */
  margin-bottom: 10px;
}

.footer-title {
  margin-bottom: 10px;
}

.footer-input {
  border: none;
  border-bottom: 1px solid white;
  background-color: transparent;
  color: white;
  margin-right: 0px;
  padding: 5px;
  width: 70%;
}

.register-button {
  background-color: transparent;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
}

.footer-icons img {
  width: 30px;
  margin-right: 10px;
}

.footer-right a {
  padding-left: 20%;
  display: block;
  /* Each link on its own line */
  color: white;
  text-decoration: none;
  margin-bottom: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-left,
  .footer-right {
    width: 100%;
    text-align: center;
  }

  .footer-icons img {
    margin: 0 auto;
  }
}

input::placeholder {
  color: white;
  /* Your desired placeholder text color */
  opacity: 1;
  /* Required for some browsers to ensure color applies */
}

.footer-input::placeholder {
  color: white;
  /* Your desired placeholder color */
  opacity: 1;
  /* Ensure the color isn't partially transparent */
  /* Add other styling as needed (font size, font style, etc.) */
}

.rights {
  display: flex;
  color: white;
  font-size: 0.8em;
  text-align: center;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
}