/* Nav.css */
nav {
  background-color: white;
}

.hr-solid {
  border: 0;
  border-top: 1px solid #d0d0d5;
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: auto;
  padding-bottom: 15px;
}


.nav-links {
  list-style: none;
  display: flex;
  padding: 0;
  margin: auto;

}

.nav-links.left {
  justify-content: flex-start;
}

.nav-links.right {
  justify-content: flex-end;
}

.nav-links li {

  padding: 10px 20px 10px 20px;
}

.nav-links a {
  color: #666666;
  text-decoration: none;
  font-size: 16px;
}

.nav-links a:hover {
  color: #000000;
}

.nav-logo {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  margin: 0px;
}

.nav-logo img {
  max-height: 100px;
  /* Adjust based on the size of your logo */
}